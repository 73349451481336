
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import FileUploader from "@/components/form-components/FileUploader.vue";

export default Vue.extend({
  name: "Create",
  components: { FileUploader },
  directives: { mask },

  data: () => ({
    rules,
    model: {} as any,
    loading: false as boolean,
    errorMessages: {} as { [value: string]: Array<string> },
    selects: {
      subdivisions: [] as Array<any>,
      directions: [] as Array<any>
    }
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const branches = await API.branches().getList();
      const directions = await API.directions().getList();

      next(vm => {
        vm.selects.branches = branches.items;
        vm.selects.directions = directions;

        vm.$nextTick(() => {
          vm.fullLoaded = true;
        });
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async submit(): Promise<void> {
      this.loading = true;
      try {
        if ((this.$refs.form as Vue).validate()) {
          await this.$API.subdivisions().create({ ...this.model });
          await this.$router.push("/subdivisions");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async getSubdivisions(branches: number) {
      try {
        const response = await this.$API.subdivisions().getList({
          filter: { branches }
        });

        this.selects.subdivisions = response.items;

        if (
          !this.selects.subdivisions.some(
            (item: any) => item.value === this.model.parent_id
          )
        ) {
          delete this.model.parent_id;
        }

        if (this.selects.subdivisions.length === 1) {
          const [subdivision_id] = this.selects.subdivisions;
          this.model.parent_id = subdivision_id;
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
